// src/pages/Settings.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Settings = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>Settings</Typography>
      <Typography variant="body1">Ini adalah halaman pengaturan.</Typography>
    </Box>
  );
};

export default Settings;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Stack,
  Alert,
  Snackbar,
  CircularProgress,
  Tooltip
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import InventoryIcon from '@mui/icons-material/Inventory';
import axios from 'axios';

const API_BASE_URL = 'https://koelkas.my.id';

const WelcomeCard = () => (
  <Card
    elevation={0}
    sx={{
      borderRadius: 2,
      background: 'linear-gradient(135deg, #000 0%, #333 100%)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    }}
  >
    <CardContent sx={{ p: '24px !important' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Send Item to Bank 🎮
        </Typography>
        <Tooltip title="Make sure to verify the Game ID before sending items">
          <IconButton size="small" sx={{ color: 'white' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography sx={{ color: 'rgba(255,255,255,0.8)', mb: 3, lineHeight: 1.6 }}>
        Fill in the form below to send items to the user's bank
      </Typography>
    </CardContent>
  </Card>
);

const FormCard = ({ children }) => (
  <Card
    elevation={0}
    sx={{
      height: '100%',
      borderRadius: 2,
      border: '1px solid rgba(0,0,0,0.08)',
      transition: 'transform 0.2s, box-shadow 0.2s',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
      },
    }}
  >
    <CardContent sx={{ p: '24px !important' }}>
      {children}
    </CardContent>
  </Card>
);

const SendItemBank = () => {
  const [enableMultiply, setEnableMultiply] = useState(false);
  const [formData, setFormData] = useState({
    user_id: '',
    item_id: '',
    plus_item: '0',
    opt_item: '0',
    quantity: '1'
  });
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    // Check if user_id and item_id are filled
    if (!formData.user_id || !formData.item_id) {
      setAlert({
        open: true,
        message: 'Please fill in all required fields',
        severity: 'error'
      });
      return false;
    }

    // Validate plus_item (allowing explicit '0' value)
    if (formData.plus_item === undefined || formData.plus_item === '') {
      setAlert({
        open: true,
        message: 'Plus Item field is required, use 0 if not applicable',
        severity: 'error'
      });
      return false;
    }

    // Validate opt_item (allowing explicit '0' value)
    if (formData.opt_item === undefined || formData.opt_item === '') {
      setAlert({
        open: true,
        message: 'Opt Item field is required, use 0 if not applicable',
        severity: 'error'
      });
      return false;
    }

    // Validate quantity if multiply is enabled
    if (enableMultiply && (!formData.quantity || parseInt(formData.quantity) < 1)) {
      setAlert({
        open: true,
        message: 'Please enter a valid quantity (minimum 1)',
        severity: 'error'
      });
      return false;
    }

    return true;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    
    // Handle empty string cases for numeric fields
    let processedValue = value;
    if ((name === 'plus_item' || name === 'opt_item') && value === '') {
      processedValue = '0';
    }
    
    setFormData(prevState => ({
      ...prevState,
      [name]: processedValue
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      
      // Ensure all numeric fields are sent as strings
      formDataToSend.append('user_id', formData.user_id);
      formDataToSend.append('item_id', formData.item_id);
      formDataToSend.append('plus_item', formData.plus_item.toString());
      formDataToSend.append('opt_item', formData.opt_item.toString());
      
      // Only append quantity if multiply is enabled
      if (enableMultiply) {
        formDataToSend.append('quantity', formData.quantity.toString());
      } else {
        formDataToSend.append('quantity', '1');
      }

      const apiUrl = `${API_BASE_URL}/src/api/send_item_bank.php`;
      
      const response = await axios({
        method: 'post',
        url: apiUrl,
        data: formDataToSend,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: false,
        timeout: 10000,
      });

      setAlert({
        open: true,
        message: response.data.message || 'Items successfully added to bank!',
        severity: 'success'
      });

      if (response.data.success) {
        // Reset form to initial state
        setFormData({
          user_id: '',
          item_id: '',
          plus_item: '0',
          opt_item: '0',
          quantity: '1'
        });
        setEnableMultiply(false);
      }

    } catch (error) {
      let errorMessage = 'An unexpected error occurred';
      
      if (error.response) {
        errorMessage = error.response.data?.message || 'Server error occurred';
      } else if (error.request) {
        errorMessage = 'No response from server. Please check your connection.';
      } else {
        errorMessage = error.message;
      }
      
      setAlert({
        open: true,
        message: `Error: ${errorMessage}`,
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert(prev => ({
      ...prev,
      open: false
    }));
  };

  return (
    <Box sx={{ bgcolor: '#f8f9fa', minHeight: '100vh', p: 3 }}>
      <Grid container spacing={3}>
        {/* Welcome Section */}
        <Grid item xs={12}>
          <WelcomeCard />
        </Grid>

        {/* Main Form */}
        <Grid item xs={12}>
          <FormCard>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                {/* Game ID Field */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 48,
                        height: 48,
                        borderRadius: '12px',
                        backgroundColor: 'rgba(0,0,0,0.04)',
                        mr: 2,
                      }}
                    >
                      <InventoryIcon sx={{ fontSize: 24, color: 'primary.main' }} />
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Game Details
                    </Typography>
                  </Box>
                  <TextField
                    required
                    fullWidth
                    id="user_id"
                    name="user_id"
                    label="ID Game"
                    variant="outlined"
                    value={formData.user_id}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ maxLength: 20 }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="item_id"
                    name="item_id"
                    label="Item ID"
                    type="number"
                    variant="outlined"
                    value={formData.item_id}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ min: "0" }}
                  />
                </Grid>

                {/* Item Details Fields */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 48,
                        height: 48,
                        borderRadius: '12px',
                        backgroundColor: 'rgba(0,0,0,0.04)',
                        mr: 2,
                      }}
                    >
                      <SendIcon sx={{ fontSize: 24, color: 'primary.main' }} />
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Item Details
                    </Typography>
                  </Box>
                  <TextField
                    required
                    fullWidth
                    id="plus_item"
                    name="plus_item"
                    label="Plus/Quantity Item"
                    type="number"
                    variant="outlined"
                    value={formData.plus_item}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ min: "0" }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="opt_item"
                    name="opt_item"
                    label="Opt Item"
                    type="number"
                    variant="outlined"
                    value={formData.opt_item}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ min: "0" }}
                  />
                </Grid>

                {/* Multiply Option */}
                <Grid item xs={12}>
                  <Box sx={{ borderTop: '1px solid rgba(0,0,0,0.08)', pt: 3 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={enableMultiply}
                          onChange={(e) => setEnableMultiply(e.target.checked)}
                          id="optSwitch"
                          disabled={isSubmitting}
                        />
                      }
                      label="Enable Multiply Item"
                    />

                    {enableMultiply && (
                      <TextField
                        fullWidth
                        id="quantity"
                        name="quantity"
                        label="How much item do you want?"
                        type="number"
                        variant="outlined"
                        value={formData.quantity}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                        inputProps={{ min: "1", max: "100" }}
                        helperText="Maximum 100 items at once"
                        sx={{ mt: 2 }}
                      />
                    )}
                  </Box>
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isSubmitting}
                    sx={{
                      mt: 2,
                      py: 1.5,
                      px: 4,
                      borderRadius: 2,
                      bgcolor: theme => theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: theme => theme.palette.primary.dark,
                      },
                    }}
                  >
                    {isSubmitting ? (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <CircularProgress size={20} color="inherit" />
                        <span>Processing...</span>
                      </Stack>
                    ) : (
                      'Send Items'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </FormCard>
        </Grid>
      </Grid>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SendItemBank;
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  Typography,
  Collapse
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const Sidebar = ({ open }) => {
  const location = useLocation();
  const [sendItemOpen, setSendItemOpen] = useState(false);

  const handleSendItemClick = () => {
    setSendItemOpen(!sendItemOpen);
  };

  const menuItems = [
    { path: '/', icon: DashboardIcon, text: 'Dashboard' },
    {
      icon: SendIcon,
      text: 'Send Item',
      subItems: [
        { path: '/send-item-bank', text: 'Send Item Bank' },
        { path: '/send-item-mall-bank', text: 'Send Item Mall Bank' },
      ],
    },
    { path: '/settings', icon: SettingsIcon, text: 'Settings' },
  ];

  const isPathActive = (path) => location.pathname === path;
  const isSendItemActive = location.pathname.startsWith('/send-item');

  return (
    <Box 
      sx={{ 
        position: 'fixed',
        top: 64,
        left: 0,
        bottom: 0,
        width: 240,
        backgroundColor: '#ffffff',
        borderRight: '1px solid rgba(0, 0, 0, 0.08)',
        transform: open ? 'translateX(0)' : 'translateX(-240px)',
        transition: 'transform 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme => theme.zIndex.drawer,
      }}
    >
      {/* Brand Section */}
      <Box 
        sx={{ 
          py: 3,
          px: 3,
          borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: '1.25rem',
            fontWeight: 600,
            letterSpacing: '-0.5px',
            color: '#000',
          }}
        >
          Monochrome
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.75rem',
            mt: 0.5,
          }}
        >
          Administrator
        </Typography>
      </Box>

      {/* Menu Container */}
      <Box sx={{ mt: 2, flexGrow: 1, overflowY: 'auto' }}>
        <List sx={{ px: 2 }}>
          {menuItems.map((item) => {
            const Icon = item.icon;
            const isActive = item.subItems 
              ? isSendItemActive 
              : isPathActive(item.path);

            if (item.subItems) {
              return (
                <React.Fragment key={item.text}>
                  <ListItem
                    onClick={handleSendItemClick}
                    sx={{
                      px: 2,
                      py: 1.5,
                      mb: 0.5,
                      borderRadius: 1.5,
                      backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                      '&:hover': {
                        backgroundColor: isActive ? 'rgba(0, 0, 0, 0.06)' : 'rgba(0, 0, 0, 0.04)',
                        cursor: 'pointer',
                      },
                      transition: 'all 0.2s ease-in-out',
                      position: 'relative',
                      overflow: 'hidden',
                      '&::before': isActive ? {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 3,
                        height: '60%',
                        backgroundColor: '#000',
                        borderRadius: '0 4px 4px 0',
                      } : {},
                    }}
                  >
                    <ListItemIcon 
                      sx={{ 
                        minWidth: 36,
                        color: isActive ? '#000' : 'rgba(0, 0, 0, 0.6)',
                      }}
                    >
                      <Icon sx={{ fontSize: '1.25rem' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary={item.text}
                      sx={{
                        m: 0,
                        '& .MuiTypography-root': {
                          fontSize: '0.875rem',
                          fontWeight: isActive ? 600 : 500,
                          color: isActive ? '#000' : 'rgba(0, 0, 0, 0.6)',
                          letterSpacing: '-0.1px',
                        }
                      }}
                    />
                    {sendItemOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={sendItemOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) => {
                        const isSubItemActive = isPathActive(subItem.path);
                        return (
                          <ListItem
                            key={subItem.path}
                            component={Link}
                            to={subItem.path}
                            sx={{
                              pl: 7,
                              py: 1,
                              '& .MuiListItemText-root': {
                                margin: 0,
                                width: 'fit-content',
                              },
                              '& .MuiListItemText-root .MuiTypography-root': {
                                position: 'relative',
                                width: 'fit-content',
                                fontSize: '0.815rem',
                                fontWeight: isSubItemActive ? 600 : 500,
                                color: isSubItemActive ? '#000' : 'rgba(0, 0, 0, 0.6)',
                                letterSpacing: '-0.1px',
                                zIndex: 1,
                                '&::before': {
                                  content: '""',
                                  position: 'absolute',
                                  left: '-8px',
                                  right: '-8px',
                                  top: '-4px',
                                  bottom: '-4px',
                                  borderRadius: '4px',
                                  backgroundColor: isSubItemActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                                  zIndex: -1,
                                  transition: 'background-color 0.2s ease-in-out',
                                },
                              },
                              '&:hover .MuiTypography-root::before': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              },
                            }}
                          >
                            <ListItemText primary={subItem.text} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }

            return (
              <ListItem
                key={item.path}
                component={Link}
                to={item.path}
                disableGutters
                sx={{
                  px: 2,
                  py: 1.5,
                  mb: 0.5,
                  borderRadius: 1.5,
                  backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                  '&:hover': {
                    backgroundColor: isActive ? 'rgba(0, 0, 0, 0.06)' : 'rgba(0, 0, 0, 0.04)',
                  },
                  transition: 'all 0.2s ease-in-out',
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': isActive ? {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: 3,
                    height: '60%',
                    backgroundColor: '#000',
                    borderRadius: '0 4px 4px 0',
                  } : {},
                }}
              >
                <ListItemIcon 
                  sx={{ 
                    minWidth: 36,
                    color: isActive ? '#000' : 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  <Icon sx={{ fontSize: '1.25rem' }} />
                </ListItemIcon>
                <ListItemText 
                  primary={item.text}
                  sx={{
                    m: 0,
                    '& .MuiTypography-root': {
                      fontSize: '0.875rem',
                      fontWeight: isActive ? 600 : 500,
                      color: isActive ? '#000' : 'rgba(0, 0, 0, 0.6)',
                      letterSpacing: '-0.1px',
                    }
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* Copyright Box */}
      <Box
        sx={{
          py: 3,
          px: 3,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          borderTop: '1px solid rgba(0, 0, 0, 0.06)',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            display: 'block',
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.75rem',
            fontWeight: 500,
            letterSpacing: '0.2px',
            lineHeight: 1.5,
            '& span': {
              fontWeight: 600,
              color: '#000',
            }
          }}
        >
          © <span>Kulkas</span> 2024
          <br />
          All rights reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
// Navbar.js
import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Menu, 
  MenuItem, 
  Avatar,
  Box 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = ({ toggleSidebar }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    console.log('Logged out');
  };

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: 64,
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleSidebar}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        
        <Typography 
          variant="h6" 
          sx={{ 
            flexGrow: 1,
            fontSize: '1.125rem',
            letterSpacing: '-0.5px'
          }}
        >
          Kulkas Panel
        </Typography>

        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            '&:hover': {
              '& .MuiTypography-root': {
                color: 'rgba(0, 0, 0, 0.7)',
              },
            },
          }}
          onClick={handleMenuClick}
        >
          <Typography
            sx={{
              mr: 1.5,
              fontSize: '0.875rem',
              fontWeight: 500,
              transition: 'color 0.2s',
            }}
          >
            Hoshikuzu
          </Typography>
          <Avatar 
            sx={{ 
              width: 32, 
              height: 32,
              bgcolor: '#000',
              fontSize: '0.875rem',
              fontWeight: 500,
            }}
          >
            H
          </Avatar>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 120,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              mt: 1,
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem 
            onClick={handleLogout}
            sx={{ 
              fontSize: '0.875rem',
              py: 1,
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  TextField,
  Button,
  Stack,
  Alert,
  Snackbar,
  CircularProgress,
  Tooltip
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import InventoryIcon from '@mui/icons-material/Inventory';
import axios from 'axios';

const API_BASE_URL = 'https://koelkas.my.id';

const WelcomeCard = () => (
  <Card
    elevation={0}
    sx={{
      borderRadius: 2,
      background: 'linear-gradient(135deg, #000 0%, #333 100%)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    }}
  >
    <CardContent sx={{ p: '24px !important' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Send Item to Item Mall Bank 🛍️
        </Typography>
        <Tooltip title="Make sure to verify the Game ID before sending items">
          <IconButton size="small" sx={{ color: 'white' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography sx={{ color: 'rgba(255,255,255,0.8)', mb: 3, lineHeight: 1.6 }}>
        Please verify the ID before sending items to the user's mall bank
      </Typography>
    </CardContent>
  </Card>
);

const FormCard = ({ children }) => (
  <Card
    elevation={0}
    sx={{
      height: '100%',
      borderRadius: 2,
      border: '1px solid rgba(0,0,0,0.08)',
      transition: 'transform 0.2s, box-shadow 0.2s',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
      },
    }}
  >
    <CardContent sx={{ p: '24px !important' }}>
      {children}
    </CardContent>
  </Card>
);

const SendItemMallBank = () => {
  const [formData, setFormData] = useState({
    OwnerID: '',
    ItemType: '',
    ItemOp1: '0',
    ItemOp2: '0',
    ItemLimit: '0'
  });

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    if (!formData.OwnerID) {
      setAlert({
        open: true,
        message: 'Please fill in the Game ID',
        severity: 'error'
      });
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      // Add current date automatically
      const currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
      formDataToSend.append('OwnerDate', currentDate);
      
      // Add other form data
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });

      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/src/api/send_item_mall.php`,
        data: formDataToSend,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
        timeout: 30000,
      });

      if (response.data.success) {
        setAlert({
          open: true,
          message: response.data.message || 'Items successfully added to mall bank!',
          severity: 'success'
        });

        setFormData({
          OwnerID: '',
          ItemType: '0',
          ItemOp1: '0',
          ItemOp2: '0',
          ItemLimit: '0'
        });
      } else {
        throw new Error(response.data.message || 'Failed to add items');
      }

    } catch (error) {
      let errorMessage = 'An unexpected error occurred';
      
      if (error.response) {
        errorMessage = error.response.data?.message || 'Server error occurred';
      } else if (error.request) {
        errorMessage = 'Connection failed. Please check your server connection.';
      } else {
        errorMessage = error.message;
      }
      
      setAlert({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
      console.error('Error details:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert(prev => ({
      ...prev,
      open: false
    }));
  };

  return (
    <Box sx={{ bgcolor: '#f8f9fa', minHeight: '100vh', p: 3 }}>
      <Grid container spacing={3}>
        {/* Welcome Section */}
        <Grid item xs={12}>
          <WelcomeCard />
        </Grid>

        {/* Main Form */}
        <Grid item xs={12}>
          <FormCard>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                {/* Game ID Field */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 48,
                        height: 48,
                        borderRadius: '12px',
                        backgroundColor: 'rgba(0,0,0,0.04)',
                        mr: 2,
                      }}
                    >
                      <InventoryIcon sx={{ fontSize: 24, color: 'primary.main' }} />
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Game Details
                    </Typography>
                  </Box>
                  <TextField
                    required
                    fullWidth
                    id="OwnerID"
                    name="OwnerID"
                    label="ID Game"
                    variant="outlined"
                    value={formData.OwnerID}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ maxLength: 20 }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="ItemType"
                    name="ItemType"
                    label="Item ID"
                    type="number"
                    variant="outlined"
                    value={formData.ItemType}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ min: "0" }}
                  />
                </Grid>

                {/* Item Details Fields */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 48,
                        height: 48,
                        borderRadius: '12px',
                        backgroundColor: 'rgba(0,0,0,0.04)',
                        mr: 2,
                      }}
                    >
                      <SendIcon sx={{ fontSize: 24, color: 'primary.main' }} />
                    </Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Item Details
                    </Typography>
                  </Box>
                  <TextField
                    required
                    fullWidth
                    id="ItemOp1"
                    name="ItemOp1"
                    label="Plus/Quantity Item"
                    type="number"
                    variant="outlined"
                    value={formData.ItemOp1}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ min: "0" }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="ItemOp2"
                    name="ItemOp2"
                    label="Opt Item"
                    type="number"
                    variant="outlined"
                    value={formData.ItemOp2}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ min: "0" }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="ItemLimit"
                    name="ItemLimit"
                    label="Duration"
                    type="number"
                    variant="outlined"
                    value={formData.ItemLimit}
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                    inputProps={{ min: "0" }}
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isSubmitting}
                    sx={{
                      mt: 2,
                      py: 1.5,
                      px: 4,
                      borderRadius: 2,
                      bgcolor: theme => theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: theme => theme.palette.primary.dark,
                      },
                    }}
                  >
                    {isSubmitting ? (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <CircularProgress size={20} color="inherit" />
                        <span>Processing...</span>
                      </Stack>
                    ) : (
                      'Send Items'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </FormCard>
        </Grid>
      </Grid>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SendItemMallBank;
import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card,
  CardContent,
  IconButton,
  Tooltip,
  LinearProgress
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleIcon from '@mui/icons-material/People';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const StatCard = ({ title, value, icon: Icon, subtitle, progress, trend }) => (
  <Card 
    elevation={0}
    sx={{
      height: '100%',
      borderRadius: 2,
      border: '1px solid rgba(0,0,0,0.08)',
      transition: 'transform 0.2s, box-shadow 0.2s',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
      },
    }}
  >
    <CardContent sx={{ p: '24px !important' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 48,
            height: 48,
            borderRadius: '12px',
            backgroundColor: 'rgba(0,0,0,0.04)',
            mr: 2,
          }}
        >
          <Icon sx={{ fontSize: 24, color: 'primary.main' }} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 0.5 }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="caption" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
        <IconButton size="small" sx={{ ml: 1 }}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Box>
      
      <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
        {value}
      </Typography>
      
      {trend && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TrendingUpIcon 
            sx={{ 
              fontSize: 16, 
              color: trend.startsWith('+') ? 'success.main' : 'error.main',
              mr: 0.5 
            }} 
          />
          <Typography 
            variant="caption" 
            sx={{ 
              color: trend.startsWith('+') ? 'success.main' : 'error.main',
              fontWeight: 500,
            }}
          >
            {trend} vs last month
          </Typography>
        </Box>
      )}
      
      {progress && (
        <Box sx={{ mt: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="caption" color="text.secondary">Progress</Typography>
            <Typography variant="caption" color="text.secondary">{progress}%</Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{ 
              height: 6, 
              borderRadius: 3,
              backgroundColor: 'rgba(0,0,0,0.04)',
            }} 
          />
        </Box>
      )}
    </CardContent>
  </Card>
);

const WelcomeCard = () => (
  <Card
    elevation={0}
    sx={{
      borderRadius: 2,
      background: 'linear-gradient(135deg, #000 0%, #333 100%)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    }}
  >
    <CardContent sx={{ p: '24px !important' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Welcome Back, Admin! 👋
        </Typography>
        <Tooltip title="Dashboard information">
          <IconButton size="small" sx={{ color: 'white' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography sx={{ color: 'rgba(255,255,255,0.8)', mb: 3, lineHeight: 1.6 }}>
        Here's what's happening with your projects today
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            Active Projects
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 600, mt: 0.5 }}>12</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            Total Tasks
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 600, mt: 0.5 }}>48</Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const Dashboard = () => {
  return (
    <Box sx={{ bgcolor: '#f8f9fa', minHeight: '100vh', p: 3 }}>
      <Grid container spacing={3}>
        {/* Welcome Section */}
        <Grid item xs={12}>
          <WelcomeCard />
        </Grid>

        {/* Stats Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Users"
            subtitle="Active accounts"
            value="1,234"
            icon={PeopleIcon}
            trend="+12.5%"
            progress={78}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Revenue"
            subtitle="Monthly earnings"
            value="$45.2k"
            icon={AccountBalanceWalletIcon}
            trend="+8.2%"
            progress={65}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Growth"
            subtitle="Annual rate"
            value="+12.5%"
            icon={TrendingUpIcon}
            trend="+3.1%"
            progress={82}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Engagement"
            subtitle="Daily active users"
            value="89%"
            icon={BarChartIcon}
            trend="+5.4%"
            progress={89}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;